import React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import { Helmet } from "react-helmet";

import Page from "../components/Page";
import { theme } from "../components/Page";

const getTagColor = (tag) => {
  switch (tag) {
    // case "sustainability":
    //   return theme.green;
    // case "philosophy":
    //   return theme.red;
    // case "tea with friends":
    //   return theme.pastel;
    default:
      return theme.pastel;
  }
};

const BlogIndex = ({ data: { allContentfulBlogPost } }) => {
  const blogPosts = allContentfulBlogPost.edges.map((post) => post.node);
  return (
    <Page>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Spill Green Tea - All Posts</title>
      </Helmet>
      <BlogPageContent>
        <div className="title-wrapper">
          <h1>
            All <span>Blog</span> Posts
          </h1>
        </div>
        <p>
          I have a lot of interests, and a lot of interesting friends. I made
          this blog as a way of sharing interesting stuff. On this page you'll
          find every post, with most recent posts on top
        </p>
        {/* <div className="quote">
          <p>
            <em>
              All of us, whether we are ignorant of philosophy or professors of
              philosophy, find it easier to follow dogma than to think.
            </em>
          </p>
          <h4>- Martha Nussbaum</h4>
        </div> */}
        {blogPosts.map((post) => {
          return (
            <PostWrapper tagColor={getTagColor(post.tags[0])} key={post.slug}>
              {" "}
              <Link to={`/blog/${post.slug}`}>
                <PostLink
                  widePicture={post.widePicture}
                  postImage={post.heroImage.fluid.src}
                  tagColor={getTagColor(post.tags[0])}
                >
                  <div className="link-image"></div>
                  <div className="post-info">
                    <h4>{post.title}</h4>
                    <p>{post.subtitle}</p>
                  </div>
                </PostLink>
              </Link>
              <div className="tags">
                {post.tags.map((tag) => {
                  return (
                    <label key={tag} className="primary-tag">
                      {tag}
                    </label>
                  );
                })}
              </div>
            </PostWrapper>
          );
        })}
      </BlogPageContent>
    </Page>
  );
};

export default BlogIndex;

export const pageQuery = graphql`
  query BlogIndexQuery {
    site {
      siteMetadata {
        title
      }
    }
    allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          subtitle
          slug
          publishDate(formatString: "MMMM Do, YYYY")
          tags
          heroImage {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
          widePicture
        }
      }
    }
  }
`;

const BlogPageContent = styled.div`
  width: 90%;
  max-width: 680px;
  margin: 0 auto;
  margin-top: 60px;
  .title-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 50px;
    h1 {
      position: relative;
      z-index: 1;
      font-size: 4.2em;
      font-weight: 200;
      span {
        color: ${({ theme }) => theme.grass};
      }
    }
  }
  .quote {
    h4 {
      font-weight: 400;
      font-size: 1.4em;
    }
  }
  a {
    text-decoration: none;
  }
`;

const PostWrapper = styled.div`
  background-color: white;
  width: 100%;
  margin-top: 35px;
  /* box-shadow: rgb(65 61 47 / 8%) 0px 7px 14px 0px,
    rgb(0 0 0 / 22%) 0px 3px 6px 0px; */

  box-shadow: rgb(126 132 163 / 20%) 0px 8px 24px;
  /* overflow: hidden; */
  border-radius: 10px;
  .tags {
    padding: 20px;
  }

  .primary-tag {
    color: ${(props) => props.tagColor};
    border: 1px solid ${(props) => props.tagColor};
    border-radius: 4px;
    padding: 4px;
    font-size: 0.9em;
    display: inline-block;
    margin-right: 20px;
    :hover {
      color: white;
      background: ${(props) => props.tagColor};
      cursor: pointer;
    }
  }
`;

const PostLink = styled.div`
  background-color: white;  
  width: 100%;
  margin-top: 20px;
  border-radius: 10px;
  @media only screen and (max-width: 700px) {
    width: 100%;
  }
  :hover {
    cursor: pointer;
    .post-info {
      color: gray;
    }
  }

  .link-image {
    height: 150px;
    overflow: hidden;
    background-image: url(${(props) => props.postImage});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
  }

  .post-info {
    padding: 20px;
    color: #383836;
    box-sizing: border-box;
    position: relative;
    h4 {
      font-size: 1.6em;
      margin: 0px;
      font-weight: 400;
      text-decoration: none;
    }
    p {
      margin-top: 10px;
      font-size: 1.2em;
      text-decoration: none;
    }
  }
`;
